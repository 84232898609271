import axios from "axios";

const baseURL = api.BASE_API

const service = axios.create({  
  baseURL: baseURL, 
	timeout: 5000, 
})

service.interceptors.request.use(
	(config) => {
		// let token = JSON.parse(sessionStorage.getItem("token"));
		config.headers = {
			"Content-Type": "application/json;charset=UTF-8",
		};
		// if (token) {
		// 	config.headers["token"] = token;
		// }
		return config;
	},
	(error) => {
		console.log(error); 
		return Promise.reject(error);
	}
)

service.interceptors.response.use(
	(response) => {
    return response;
	},
	(error) => {
		console.log("err" + error); 
    
		console.log({
			message: error.message,
			type: "error",
			duration: 5 * 1000,
		});
		return Promise.reject(error);
	}
)

export default service


