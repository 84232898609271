import {getToken, setToken} from '@/utils/auth'
import {login} from '@/api/index'
import { Message } from 'element-ui'

const getDefaultState = () => {
  return {
    token: getToken() || ''
  }
}

const state = getDefaultState()

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  }
}

const actions = {
  login({commit}, userInfo){
    return new Promise((resolve, reject) => {
      login(userInfo).then(res => {
        const data = res.data
        setToken(data.token)
        commit('SET_TOKEN', data.token)
      }).catch(error => {
        Message({
          message: '用户名或密码错误',
          type: 'error',
          duration: 2 * 1000
        })       
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}