import request from '@/utils/request';

//获取合格证详情
export function GetQCRecordByID(id) {
	return request({
		url: "/api/GetV_PrintsInfoByID/" + id,
		method: "GET",
	})
}

export function login() {
	return request({
		url: "",
		method: "GET",
	})
}