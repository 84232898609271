import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import '@/assets/common/base.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/utils/rem'

Vue.use(ElementUI);
// Vue.prototype.baseURL = process.env.VUE_APP_BASE_API;
Vue.config.productionTip = false
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
})
